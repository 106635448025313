import request from "@/utils/request";
var token = localStorage.getItem("token"); //获取浏览器缓存token
var uid = localStorage.getItem("yh_id");
export function login(data) {
  return request({
    url: "/login",
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}

// 根据token获取用户信息
export function getUserInfoByToken(data) {
  return request({
    url: "/user/getUserInfoByToken",
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}

export function city() {
  return request({
    url: "city.json",
    method: "get",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}

// 商户获取参数值
export function getParams() {
  return request({
    url: "/merchant/getParams",
    method: "get",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}

export function logout() {
  return request({
    url: "/user/logout",
    method: "post",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}

// 账号管理--获取账号列表
export function usersList(params) {
  return request({
    url: "user/usersList",
    method: "get",
    params: params,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
    },
  });
}

//账号列表
export function usersListSupplier(params) {
  return request({
    url: "user/usersListSupplier",
    method: "get",
    params: params,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}

// 账号管理--删除列表信息
export function delUser(data) {
  console.log(data);
  return request({
    url: "user/delUser",
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}

// 账号管理--获取角色组
export function getRoles(params) {
  return request({
    url: "user/getRoles",
    method: "get",
    params: params,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}

// 账号管理--新增账号
export function addUser(data) {
  console.log(data);
  return request({
    url: "user/addUser",
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}

// 账号管理--编辑修改账号
export function updateUser(data) {
  console.log(data);
  return request({
    url: "user/updateUser",
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}

// 账号管理--获取单个详细信息
export function userDetail(params) {
  console.log(params);
  return request({
    url: "user/userDetail",
    method: "get",
    params: params,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}

// 账号管理--批量操作
export function setAll(data) {
  console.log(data);
  return request({
    url: "user/setAll",
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}

// 权限管理--角色列表
export function rolesList(params) {
  return request({
    url: "role/rolesList",
    method: "get",
    params: params,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}

// 短信登录
export function sendSms(data) {
  //console.log(data,'--ss---')
  return request({
    url: "sendSms",
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
}

// 删除角色列表信息
export function delRole(data) {
  console.log(data);
  return request({
    url: "role/delRole",
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}

// 添加角色
export function addRole(data) {
  return request({
    url: "role/addRole",
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}

// 获取菜单列表（角色授权列表）
export function menuNameByMerchantList(data) {
  return request({
    url: "menu/menuNameByMerchantList",
    method: "get",
    data: data,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}

// 角色修改
export function updateRole(data) {
  return request({
    url: "role/updateRole",
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}

// 角色详情
export function getInfo(data) {
  return request({
    url: "role/getInfo",
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}

// getLoginUserInfo
export function getLoginUserInfo() {
  return request({
    url: "platform/getLoginUserInfo",
    method: "get",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}
